
import { Options, Vue } from "vue-class-component";
import moment from "moment";
import { SampleSpecimenStatus } from "@/middlewares/enum";
import { Notify } from "vant";

@Options({
  components: {
    [Notify.Component.name]: Notify.Component,
  },
})
export default class NoSceneSpecimenList extends Vue {
  private sampleSpecimenStatus = SampleSpecimenStatus;
  private refreshing = false;
  private finished = false;
  private specimenList: any = [];
  private pageIndex = 1;
  private loading = false;
  private searchForm: any = {
    code: "", //采集编号
    name: "", //采集居民
    startDate: moment(new Date()).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  };
  public created() {
    (window as any).callJS2 = (msg: any) => {
      //和flutter一样的方法
      return this.callJS(msg);
    };
    console.log(localStorage.length);
    console.log(localStorage.getItem("userInfo"));
    console.log(this.$store.state.userInfo);
    if (this.$store.state.userInfo) {
      localStorage.setItem("token", this.$store.state.userInfo.token);
    }
  }
  private callJS(message: any) {
    console.log(message);
    return "call js ok";
  }

  //下拉刷新
  private onRefresh() {
    // 清空列表数据
    this.finished = false;
    this.pageIndex = 1;
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    this.loading = true;
    this.findSceneSpecimenList();
  }
  private doSearch() {
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }
  //重置查询
  private resetSearch() {
    this.searchForm = {
      code: "", //采集编号
      name: "", //采集居民
      startDate: moment(new Date())
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    };
    this.refreshing = true;
    this.onRefresh();
    const ref: any = this.$refs["dropdownSearch"];
    ref.toggle(false);
  }
  private findSceneSpecimenList() {
    if (this.refreshing) {
      this.specimenList = [];
      this.refreshing = false;
    }
    this.loading = true;
    const url = "/api/lims/sampleSpecimen/getSceneSpecimenList";
    this.axios
      .get(url, {
        params: {
          code: this.searchForm.code,
          userId: this.$store.state.userInfo.id,
          pageSize: 20,
          pageIndex: this.pageIndex,
          itemStatus:'0,5',
          specimenStatus:'0,1,5,10,15,16,20',
        },
      })
      .then((res: any) => {
        //关闭加载
        this.loading = false;
        if (res.status.code !== 1) {
          this.$toast.fail(res.status.msg);
        } else {
          for (const record of res.data.records) {
            record.checked = false;
          }
          this.specimenList = this.specimenList.concat(res.data.records);
        }
        if (
          !res.data.records ||
          res.data.records.length === 0 ||
          this.specimenList.length === res.data.total
        ) {
          this.finished = true;
        } else {
          this.pageIndex++;
        }
      })
      .catch((err) => {
        this.$toast.fail(err);
      });
  }

  private getSampleSpecimenStatusDesc(scope: any) {
    for (const item of this.sampleSpecimenStatus) {
      if (scope === item.code) {
        return item.name;
      }
    }
  }
  private formatDataTime(time: any) {
    return moment(time).format("YYYY-MM-DD");
  }

  private showSpecimen(item: any) {
    //打开样本详情
    // const temp: any = this.$refs.sceneSpecimenResultSaveRef;
    // temp.findSpecimenAndSceneItem(item);
    // location.href = "/sceneSpecimenResultSaveH5?id="+item.id;
    this.$router.push({ path: '/sceneSpecimenResultSaveH5', query: { id:item.id } });
  }
}
